<template>
  <div class="public">
    <div class="left-con">
      <div class="title">页面预览</div>
      <div class="phone">
        <div class="top">
          <img src="@/assets/img/information/top.png" />
        </div>
        <div class="page-btn">
          <img class="arrow" src="@/assets/img/information/arrow.png" />
          <span>详情</span>
          <img class="more" src="@/assets/img/information/more.png" />
        </div>
        <div class="info-con">
          <div class="info-title">{{ form.noticeTitle }}</div>
          <div class="detail-con">
            <div class="detail-con-1">
              <div>作者：{{ form.noticeAuther }}</div>
              <div>{{ form.viewNum }}人浏览</div>
            </div>
            <div>发布时间：{{ form.publishTime }}</div>
          </div>
          <!-- <div class="img-box" v-if="form.url">
            <el-image :src="imgUrl + form.url" fit="cover"></el-image>
          </div> -->
          <div class="rich-text-con" v-html="form.noticeContent"></div>
        </div>
      </div>
    </div>
    <el-form
      :rules="dataRules"
      label-width="100px"
      :model="form"
      ref="form"
      v-loading="dataListLoading"
    >
      <el-form-item v-if="edit" label="编号" prop>{{
        form.noticeNumber
      }}</el-form-item>
      <el-form-item label="标题" prop="noticeTitle">
        <el-input
          placeholder="请输入"
          v-model.trim="form.noticeTitle"
          class="wd400"
        ></el-input>
      </el-form-item>
      <el-form-item label="内容分类" prop="noticeType">
        <el-select
          placeholder="请选择"
          v-model="form.noticeType"
          clearable
          class="wd400"
        >
          <el-option
            v-for="(item, index) in typeList"
            :key="index"
            :value="item.value"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="内容封面">
        <single-upload
          v-model="form.url"
          :isShowTip="true"
          style="width: 350px"
        ></single-upload>
      </el-form-item>
      <el-form-item label="内容摘要">
        <el-input
          type="textarea"
          :rows="5"
          placeholder="请输入内容"
          style="width: 645px"
          v-model="form.noticeSummary"
        ></el-input>
      </el-form-item>
      <el-form-item label="跳转链接">
        <el-input
          placeholder="如果需要跳转到第三方，请在此添加外部链接"
          v-model="form.noticeRedirectUrl"
          class="wd400"
        ></el-input>
      </el-form-item>
      <el-form-item label="正文内容" prop="noticeContent">
        <!-- 123 -->
        <tiny-mce v-model="form.noticeContent"></tiny-mce>
      </el-form-item>
      <el-form-item label="排序权重">
        <el-input
          placeholder="默认为0，权重值越大，服务排序越靠前"
          v-model="form.orderWeight"
          class="wd400"
        ></el-input>
      </el-form-item>
      <el-form-item label="浏览次数">
        <el-input
          v-model="form.viewNum"
          type="number"
          class="wd400"
          oninput="value=value.replace(/[^0-9.]/g,'')"
        ></el-input>
      </el-form-item>
      <el-form-item label="作者" prop="noticeAuther">
        <el-input v-model="form.noticeAuther" class="wd400"></el-input>
      </el-form-item>
      <el-form-item label="发布时间">
        <el-date-picker
          v-model="form.publishTime"
          type="datetime"
          placeholder="选择日期时间"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          style="width: 400px"
        >
          <!-- value-format='yyyy-MM-DD HH:mm:ss' -->
          <!-- format="yyyy 年 MM 月 dd 日" -->
        </el-date-picker>
      </el-form-item>
      <el-form-item class="el-form-item-nolable2">
        <el-button type="primary" @click="submit" v-points>保存</el-button>
        <el-button @click="back">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import TinyMce from "@/components/tiny-mce";
import SingleUpload from "@/components/Upload/singleUpload";
import Qs from "qs";
import moment from "moment";
export default {
  name: "publicAdd",
  components: {
    TinyMce,
    SingleUpload,
  },
  data() {
    return {
      edit: false,
      form: {
        noticeTitle: "",
        noticeType: "",
        noticeSummary: "",
        noticeRedirectUrl: "",
        noticeContent: "",
        noticeAuther: "",
        orderWeight: "",
        publishTime: "",
        viewNum: "",
        url: "",
      },
      diseasesList: [],
      typeList: [],
      dataRules: {
        noticeTitle: [
          { required: true, message: "标题不能为空", trigger: "blur" },
        ],
        noticeType: [
          { required: true, message: "请选择分类", trigger: "change" },
        ],
        // workEnertyType: [{ required: true, message: "请选择宣教类型", trigger: "change" }],
        noticeContent: [
          { required: true, message: "正文内容不能为空", trigger: "blur" },
        ],
        noticeAuther: [
          { required: true, message: "作者不能为空", trigger: "blur" },
        ],
      },
      dataListLoading: false,
      type: "",
      imgUrl: this.$global.imgUrl,
    };
  },
  async mounted() {
    this.form.publishTime = moment().format("YYYY-MM-DD HH:mm:ss");
    let query = this.$route.query;
    if (query.id) {
      this.edit = true;
      this.dataListLoading = true;
      await this.init(query.id);
    }
    if (query.contentType) {
      this.form.noticeType = parseInt(query.contentType)
    }
    await this.getDictList();
  },
  methods: {
    back() {
      this.$router.back();
    },
    async init(id) {
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/notice/getNotice"),
        method: "post",
        params: { stringParam1: id },
      });
      if (res.status) {
        this.form = res.data;
        this.dataListLoading = false;
      }
    },
    // 获取字典
    async getDictList() {
      let _this = this;
      let teamsId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
      }
      const { data } = await this.$httpAes({
        url: _this.$httpAes.adornUrl("/dict/getSysDictList?type=" + "公告分类"),
        method: "post",
        params: {
          stringParam2:teamsId,
		  stringParam1:'公告分类',
          intParam1: 999,
        },
      });

      if (data.status) {
        _this.typeList = data.data;
      }
    },
    async submit() {
      const user = JSON.parse(localStorage.getItem("userInfo"));
      let teamsId = "";
      let enertyId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      if (this.type) {
        this.form.noticeType = this.type;
      }
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let form = this.form;
          form.classify = 2;
          form.hospitalId = teamsId;
          form.deptId = enertyId;
          const { data: res } = await this.$httpAes({
            url: this.$httpAes.adornUrl(
              form.id ? "/notice/update" : "/notice/add"
            ),
            method: "post",
            data:this.form,
          });
          if (res.status) {
            if (res.status) {
              this.$message({
                message: res.msg,
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.$router.push({
                    name: "information",
                  });
                },
              });
            }
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.public {
  display: flex;
  .left-con {
    padding-top: 44px;
    padding-right: 24px;
    border-right: 1px solid #eee;
    height: 100%;
    .title {
      font-size: 16px;
      text-align: center;
      padding-bottom: 24px;
    }
    .phone {
      width: 375px;
      height: 667px;
      border: 1px solid #979797;

      .top {
        width: 100%;
        height: 20px;
        img {
          width: 100%;
          height: 20px;
        }
      }
      .page-btn {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        height: 44px;
        padding: 0 15px;
        span {
          font-size: 18px;
        }
        .arrow {
          width: 10px;
          height: 17px;
        }
        .more {
          width: 21px;
          height: 4px;
        }
      }
      .info-con {
        padding: 15px;
        height: calc(100% - 64px);
        overflow: auto;
        .info-title {
          font-size: 20px;
          font-weight: bold;
        }
        .detail-con {
          color: #999999;
          padding: 10px 0 18px;
          .detail-con-1 {
            display: flex;
            justify-content: space-between;
            padding-bottom: 10px;
          }
        }
        .img-box {
          width: 100%;
          height: 290px;
          padding-bottom: 15px;
          .el-image {
            width: 100%;
            height: 100%;
          }
        }
        .rich-text-con {
          /deep/p {
            word-break: break-all;
            img {
              width: 100% !important;
              height: auto !important;
            }
          }
        }
      }
    }
  }
}
</style>
